import * as yup from "yup";

export const TourSchema = yup.object().shape({
  transport_agent_id: yup.number().required("Required"),
  name: yup.string().required("Required"),
  description: yup.string().nullable(),
  active: yup.bool(),
  delivery_timings: yup.array().of(yup.string().required("Required")).required("Required").min(1, "Required"),
  allowed_supplier_locations: yup.array().of(yup.number().required("Required")).required("Required").min(1, "Required"),
  supplier_location_id: yup.number().nullable().required("Required"),
  user_defined_tour_id: yup.number().nullable().required("Required"),
});

export const CustomerSchema = yup.object().shape({
  tour_id: yup.string().required("Required"),
  name: yup.string().required("Required"),
  number: yup.string().max(10, "Less than 10 characters").required("Required"),
  street: yup.string().required("Required"),
  street_number: yup
    .string()
    .transform((v) => v?.trim())
    .required("Required"),
  city: yup.string().required("Required"),
  zipcode: yup
    .string()
    .matches(/^\d{5}$/, "Required")
    .required("Required"),
  country: yup.string().required("Required"),
  email: yup.string().email("Invalid email").nullable(),
  phone: yup
    .string()
    .matches(/^[\d\s+]*$/i, "Ungültige Nummer")
    .nullable(),
  latitude: yup.string().nullable(),
  longitude: yup.string().nullable(),
  deposit_agreement: yup.string().required("Required"),
  contact_salutation: yup.string().nullable(),
  contact_name: yup.string().nullable(),
  contact_surname: yup.string().nullable(),
  keybox_code: yup.mixed().when("deposit_agreement", {
    is: "KEY_BOX",
    then: yup.string().required("Required"),
    otherwise: yup.string().nullable(),
  }),
  priority: yup.bool(),
  supplier_id: yup.number().nullable(),
  duplicate: yup.bool().nullable(),
  formatted_address: yup.string().nullable(),
});

export const SupplierLocationSchema = yup.object().shape({
  supplier_id: yup.string().nullable().required("Required"),
  name: yup.string().required("Required"),
  street: yup.string().required("Required"),
  street_number: yup
    .string()
    .transform((v) => v?.trim())
    .required("Required"),
  city: yup.string().required("Required"),
  zipcode: yup
    .string()
    .matches(/^\d{5}$/, "Required")
    .required("Required"),
  country: yup.string().required("Required"),
  email: yup.string().email("Invalid email").nullable().required("Required"),
  phone: yup
    .string()
    .nullable()
    .matches(/^[\d\s+]*$/i, "Ungültige Nummer")
    .required("Required"),
  latitude: yup.string().nullable(),
  longitude: yup.string().nullable(),
  formatted_address: yup.string().nullable(),
  filename: yup.mixed().nullable(),
});

export const OrderSchema = yup.object().shape({
  customer_id: yup.number().required("Required"),
  description: yup.string().nullable(),
  number: yup.string().max(20, "Less than 20 characters").required("Required"),
  packages: yup.number().min(0, "Invalid Number").typeError("Required").nullable(),
  departure_date: yup.date().typeError("Ungültiges Datum").required("Required"),
  remarks: yup.string().nullable(),
  departure: yup.string().nullable().required("Required"),
  express: yup.boolean().required(),
});

export const ZipcodeSchema = yup.object().shape({
  zipcode: yup
    .string()
    .matches(/^\d{5}$/, "Zipcode must be of 5 numbers")
    .required("Required"),
  tour_id: yup.number().typeError("Required").required("Required"),
});
